/** @jsx jsx */
import {jsx, css} from "@emotion/core"
import React from "react"
import dayjs from "dayjs"
import "dayjs/locale/nl"

import {PageProps} from "gatsby"
import {Document} from "../../types"

import Layout from "../components/layout"
import PageWidth from "../components/page-width"
import SingleBanner from "../components/single-banner"
import {Theme} from "../styles/theme"

const DocumentTemplate = (page: PageProps): JSX.Element => {
  const data = page.pageContext as Document
  const baseUrl = (url: string) => new URL(url).host
  dayjs.locale("nl")

  return (
    <Layout title={data.title} currentPage={data}>
      <SingleBanner
        bannerTitle="Download documenten"
        bannerImage={{
          url:
            "https://www.datocms-assets.com/37731/1610466097-documents-banner.jpg?w=1600",
        }}
        size="small"
      />
      <PageWidth>
        <div css={TwoColumns}>
          <div>
            <h3>{data.title}</h3>
            {data.archived && (
              <span className="archived">Dit document is gearchiveerd.</span>
            )}
            <h4>Samenvatting</h4>
            <div dangerouslySetInnerHTML={{__html: data.content}}></div>
          </div>
          <aside>
            <div css={Downloads}>
              <h4>Downloads</h4>
              {data.downloads.map((download, index) => (
                <React.Fragment key={index}>
                  <a href={download.url} target="_blank" rel="noreferrer">
                    {(download.title || download.basename).replace(/-/g, " ")}
                  </a>
                  <span>{download.author}</span>
                </React.Fragment>
              ))}
              {data.externalDownloads.map((download, index) => (
                <React.Fragment key={index}>
                  <a
                    href={download.destination}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {download.text || baseUrl(download.destination)}
                  </a>
                </React.Fragment>
              ))}
            </div>
            <div css={Metadata}>
              {data.publicationDate && (
                <>
                  <span>Publicatiedatum</span>
                  <span>
                    {dayjs(data.publicationDate).format("D MMMM YYYY")}
                  </span>
                </>
              )}
              {data.author && (
                <>
                  <span>Opsteller</span>
                  <span>{data.author}</span>
                </>
              )}
              {data.documentType && (
                <>
                  <span>Type document</span>
                  <span>{data.documentType.name}</span>
                </>
              )}
            </div>
          </aside>
        </div>
      </PageWidth>
    </Layout>
  )
}

export default DocumentTemplate

const TwoColumns = css`
  > div {
    line-height: 3.4rem;
    margin-bottom: 5rem;
  }

  @media ${Theme.breakpoints.medium} {
    display: flex;
    justify-content: space-between;

    > div,
    aside {
      width: 48%;
    }
  }

  span.archived {
    display: block;
    margin-bottom: 2rem;
    color: ${Theme.colors.identity.primary};
    font-weight: 400;
  }
`

const Downloads = css`
  margin-bottom: 5rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 2rem rgba(106, 106, 106, 0.3);
  padding: 3rem 4rem;

  h4 {
    margin-top: 0;
  }

  a,
  span {
    display: block;
    font-weight: 300;
  }

  a:first-letter {
    text-transform: capitalize;
  }

  a:not(:first-of-type) {
    margin-top: 2rem;
  }

  span {
    font-size: 1.7rem;
    color: ${Theme.colors.fontcolors.light};
  }
`

const Metadata = css`
  border-left: 0.2rem solid ${Theme.colors.identity.primary};
  padding: 2rem 0 2rem 4rem;

  span {
    display: block;

    &:nth-of-type(odd) {
      margin-bottom: 0.5rem;
      font-weight: 400;
    }
    &:nth-of-type(even) {
      margin-bottom: 2rem;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`
